import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';

import { BrowserRouter } from 'react-router-dom';


const domainGroupId = 'e2f7cb41-b803-404a-a109-2aceddafd9dc';

ReactDOM.render(

    <BrowserRouter>

              <Routes />
              
              
    </BrowserRouter>
    ,
  document.getElementById('root')
);

