import React from "react";
import {
  Box,
  Heading,
  Grommet,
  Image,
  Footer
} from 'grommet';
import Report from './Report'



import { deepFreeze } from "grommet/utils"

export const customTheme = deepFreeze(
  {
  "global": {
    "colors": {
      "brand": "#6495ED",
      "control": {
        "dark": "#0047AB",
        "light": "#000000"
      },
      "focus": "#1163f5",
      "accent-1": "#FFED00",
      "accent-2": "#00C9FF",
      "dark-1": "#000001",
      "dark-2": "#333333",
      "dark-3": "#666666",
      "light-1": "#D9D9D9",
      "light-2": "#AAADAE",
      "neutral-1": "#00C9FF",
      "neutral-2": "#64FF00",
      "neutral-3": "#D0011B",
      "neutral-4": "#FFED00",
      "status-critical": "#D0011B",
      "status-warning": "#FFED00",
      "status-ok": "#64FF00",
      "status-unknown": "#AAADAE",
      "status-disabled": "#AAADAE"
    },
    "font": {
      "family": "Roboto"}
  },
  "anchor": {
    "color": {
      "dark": "#FFED00",
      "light": "#000000"
    }
  }
}
)
      
      

const AppBar = (props) => (
  
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    margin="medium"
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    
    style={{ zIndex: '1' }}
    {...props}
  />
);

function ReportHolder() {

 
  return (

    <Grommet theme={customTheme} full>
       
          <Box fill='horizontal'>
            <AppBar>
            
              <Box align="center">
                <Heading  level='3' margin='large' color="white">ChatterChart</Heading>
              </Box>
            </AppBar>
            <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
              
              <Box fill='horizontal' align='center' justify='center'>
                
                <Report/>
                
              </Box>
              
            </Box>
            
          </Box>
     
      <Footer background="brand" align='center' pad='medium'>
          
        </Footer>
    </Grommet>
    
  
  );
}

export default ReportHolder;
