import React, { Component, Fragment } from "react";
import axios from 'axios';
import {
    Box,
    Heading,
    Paragraph,
    
    
  } from 'grommet';


import ChartTwo from './ChartTwo'





axios.defaults.headers.post['Content-Type'] ='application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['X-Api-Key'] = 'jdsid9393nUDJDkksiDJDI22393HWIO';


  class Report extends Component {
    constructor(props){
      super(props);
      this.state = {'checked': true};
    }

    
    handleCheck(e) {
        this.setState(prevState => ({ checked: !prevState.checked }))
      }

    
  render(){
  return (

    <Fragment>
      
          <Box direction="row">
            <Heading>ChatterChart</Heading>
            
          </Box>
          <Paragraph margin={{ top: "none" }} textAlign="center" size="xxlarge">
            The ChatterChart fitness demo allows you to search a small subset of 250 youtube fitness influencers by keyword. You will be able to analyse each influencers content in more detail by interrogating a knowledge graph of their recent content. 
          </Paragraph>

          <Box direction='row' justify='center' gap='medium' overflow='auto' wrap='true'>
         <Paragraph margin={{ top: "none" }} textAlign="center" size="medium">When you submit your query you will be presented with a prioritised list of influencers for your consideration who have created relevant content.<br></br></Paragraph>
          <Paragraph margin={{ top: "none" }} textAlign="center" size="medium">If you are launching a new pair of trainers you might search <b>"Running"</b> to see which influencers talk about this frequently. <br></br></Paragraph>
          <Paragraph margin={{ top: "none" }} textAlign="center" size="medium">If you are a nutrition company, you may want to see which influencers your competition have relationships with - <b>"MyProtein"</b><br></br></Paragraph>
          <Paragraph margin={{ top: "none" }} textAlign="center" size="medium">You might be an organisation who wants to work with influencers who are consistent with your brand. Influencers discussing <b>"Steroids"</b> might be inconsistent.<br></br></Paragraph>
          </Box>
                    
         
            <ChartTwo/>
            

    </Fragment>
  );
            }
}

export default Report;





