import React from "react";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import ReportHolder from './ReportHolder';
import NotFound from './NotFound';
import MediaQuery from "react-responsive";

export default function Routes() {
  
    return (
      <div>
        <Switch>
          <Route exact path="/">
            <MediaQuery minWidth={1024}>
              <ReportHolder />
            </MediaQuery>
            <MediaQuery maxWidth={1023}>
              <p>Unfortunately ChatterChart is not available on mobile device</p>
            </MediaQuery> 
          </Route>
        
         
          <Route>
              <NotFound />
          </Route>
          
        </Switch>
        </div>
    );
  }

function WaitingComponent(Component) {
  return props => (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </React.Suspense>
  )
}