import React, { Component, Fragment } from "react";
import axios from 'axios';
import {
    Box,
    Text,
    Button,
    Paragraph,
    TextInput,
    Layer,
    Anchor,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Image,
    Header
  } from 'grommet';

import Loader from 'react-loader-spinner'
import Graph from "react-graph-vis";
import './MotForm.css'

const options = {
  layout: {
    hierarchical: false,
    improvedLayout: false
  },
  edges: {
    color: "#0066FF",
    font: {
      color: '#343434',
      size: 14, // px
      face: 'arial',
      background: 'none'},
    smooth: {
        type: "curvedCW",
        forceDirection: "none",
        roundness: 0.5   // This is max roundness
      },
  
  },
  physics: {
    stabilization: {
      enabled: true,
      iterations: 5000    // YMMV
    }
  },
  
  height: "100%",
  width: "100%"};
  


axios.defaults.headers.post['Content-Type'] ='application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['X-Api-Key'] = 'jdsid9393nUDJDkksiDJDI22393HWIO';
axios.defaults.headers.post['Access-Control-Allow-Headers'] = '*';





class ChartTwo extends Component {
    constructor(props){
      super(props);

      
      this.state={'datasets': ''}
      this.state={'query': ''}
      this.state={'loading': false}
      this.state={'modal_loading': false}
      this.state={'error': 'noerror'}
      this.state={'modal_error': 'noerror'}
      this.state={"modal_id": ''}
      this.state={"graph_data": ''}
      this.state={"graph_channel_id": ''}
      this.state={"graph_channel_name": ''}
      this.state={"graph_pic_url": ''}
      this.state={"graph_video_count": ''}
      this.state={"graph_subscriber_count": ''}
      this.state={"graph_view_count": ''}
      this.state={"graph_description": ''}
      this.state={"graph_html": ''}
      this.state={"graph_common_keys": ''}
      this.state={"nodes": ''}
      this.state={"edges": ''}
      this.state={"results": ""}


      this.state = {
        layerActive: false
      };
      this._onClick = this._onClick.bind(this);
    }



    _onClick(channel_id) {
    
    this.setState({modal_id: channel_id});
    this.setState(prevState => ({ modal_loading: !prevState.isLoading }));
    this.setState({nodes: ''})
    this.setState({edges: ''})
      
       
       this.setState({modal_error: 'noerror'});
       axios.post('http://inf.dfworks.xyz/graph', {"channel_id": channel_id, "db": "fitness", "col": "fitness_1"})
      .then((response) => {
        console.log(response.data);
        
        this.setState({graph_channel_id: response.data["channel_id"]});
        this.setState({graph_channel_name: response.data["channel_name"]});
        this.setState({graph_pic_url: response.data["pic_url"]});
        this.setState({graph_video_count: response.data["video_count"]});
        this.setState({graph_description: response.data["description"]});
        this.setState({graph_html: response.data["html"]});
        this.setState({graph_common_keys: response.data["common_keys"]});
        this.setState({graph_subscriber_count: response.data["subscriber_count"]});
        this.setState({graph_video_count: response.data["video_count"]});
        this.setState({nodes: response.data["nodes"]})
        this.setState({edges: response.data["edges"]})
        
        

        this.setState(this.state);
        this.setState(prevState => ({ modal_loading: false}));
        this.setState({ layerActive: true });
          
      }, (error) => {
        console.log(error);
        this.setState({modal_error: 'error'});
        this.setState(prevState => ({ modal_loading: false}));
      }
      );}
      
    
    
    handleQuery(e){
      this.setState({query: e.target.value});
    } 


    handleSubmit(e){
       //call the api here with current state value (this.state.username)
       this.setState(prevState => ({ loading: !prevState.isLoading }));
       e.preventDefault();
       
       this.setState({error: 'noerror'});
       axios.post('http://inf.dfworks.xyz/get-list', {"query": this.state.query, "db": "fitness", "col": "fitness_1"})
      .then((response) => {
        console.log(response.data);
        response.data.length == 0 ? this.setState({results: 'notfine'}) : this.setState({results: 'fine'})
        this.setState({datasets: response.data});
        this.setState(this.state);
        this.setState(prevState => ({ loading: false}));
        
          
      }, (error) => {
        console.log(error);
        this.setState({error: 'error'});
        this.setState(prevState => ({ loading: false}))
      }
      );
      
    
      
    }
 
  
  render(){
    const layer = (this.state.layerActive)
      ? <Layer flex="true"closer={true} flush={true} onClose={()=>{this.setState({layerActive:false})}}>
          <Box margin="medium" width="xlarge" fill>
                    <Header justify="center" pad="medium"><Text size="large" weight="bold">{this.state.graph_channel_name}</Text></Header>
                    <Box pad="medium" gap="medium" fill>
                    <Box justify='center' direction="row" pad="small" gap="small" align="center" alignContent="center" flex="true">
                        <Box overflow="auto">
                        <Paragraph>{this.state.graph_description}</Paragraph>
                            </Box>
                        <Box height='medium' width="large" border={{ color: 'brand', size: 'large' }} background={{opacity:true, image:"url('loading.jpg')"}}>     
                            <Graph graph={{nodes:this.state.nodes, edges:this.state.edges}} options={options} />
                        </Box> 
                    </Box>
                    
                    
                    <Box direction='row' fill>
                    <Box pad="small" align="center" alignContent="center" flex="true">
                    <Header justify="center" pad="medium"><Text size="large" weight="bold">Statistics</Text></Header>
                        <Paragraph size="medium"><b>Video Count</b> - {this.state.graph_video_count}</Paragraph>
                        <Paragraph  size="medium"><b>Subscriber Count</b> - {this.state.graph_subscriber_count}</Paragraph>
                        <Paragraph  size="medium"><b>View Count</b> - {this.state.graph_view_count}</Paragraph>                                
                    </Box>
                    <Box pad="small" align="center" alignContent="center" flex="true">
                    <Header justify="center" pad="medium"><Text size="large" weight="bold">Recent Discussed Themes</Text></Header>
                    {
                      Object.keys(this.state.graph_common_keys).map((key, index) => ( 
                        <Paragraph size="medium"> <b>{this.state.graph_common_keys[key][0]}</b> </Paragraph> 
                      ))
                    }                  
                    </Box>
                    </Box>
                    
                    
                    </Box>
                    <Box height='small' gap='medium' margin='medium' fill/>
                    <Box width='xlarge' alignSelf='center' justify='center' align='center' direction='row' gap='medium' pad="medium" margin='medium' background="light-2" >
                        <Anchor href={"https://www.youtube.com/channel/" + this.state.graph_channel_id} label="Visit Channel" />

                            <Box full={true} colorIndex="light-2" justify="center" align="center">
                              <Button label="close" onClick={()=>{this.setState({layerActive:false})}} />
                            </Box>
                            
                        
                    </Box>
              </Box>

        </Layer>
      : null;
    
  return (

    <Fragment>
      
          
          <form className="column">
                    
                    <h2 style={{textAlign:'center'}}>Enter Query below</h2> 
                    
                    <TextInput onChange={this.handleQuery.bind(this)} type="text" name="query" placeholder="Type Query" />
                    <button onClick={this.handleSubmit.bind(this)} type="submit">Submit</button>
                    {this.state.loading ? <div className='spinner' style={{height: '30px'}}>
                                            <Loader
                                                type="Puff"
                                                color="black"
                                                height={30}
                                                width={30}
                                                timeout={5000}
                                                                                
                                            />
                                        </div> : <div className='spinner' style={{height: '30px'}}> </div>}
                </form>
                
                

            

            { (this.state.error == 'error') 
            ? (<h6 style={{textAlign:'center', color:'red'}}>There has been an error, please submit a different query.</h6>) 
            : (null)}            
            
            { (this.state.datasets == null) 
            ? (null) 
            : 
            <div>

            { (this.state.results == 'notfine') 
            ? (<h6 style={{textAlign:'center', color:'red'}}> Unfortunately our dataset isnt currently large enough to contain that term.</h6>) 
            : (null)}

            {this.state.datasets.map(({ channel_name, channel_id, pic_url, video_count, subscriber_count, view_count, description }) => (
                
   
                
                <Card background="light-1" margin="medium" width="xlarge">
                    <CardHeader justify="center" pad="medium"><Text size="large" weight="bold">{channel_name}</Text></CardHeader>
                    <CardBody pad="medium" gap="medium">
                    <Box justify='center' direction="row" pad="small" gap="small" align="center" alignContent="center" flex="true">
                        <Box overflow="auto">
                        <Paragraph>{description}</Paragraph>
                            </Box>
                        <Box height="medium" width="medium">     
                            <Image fill="vertical" fit="contain" src={pic_url} fallback={pic_url}/>
                        </Box> 
                    </Box>
                    <Box pad="small" align="center" alignContent="center" flex="true">
                        <Paragraph size="large"><b>Video Count</b> - {video_count}</Paragraph>
                        <Paragraph  size="large"><b>Subscriber Count</b> - {subscriber_count}</Paragraph>
                        <Paragraph  size="large"><b>View Count</b> - {view_count}</Paragraph>                                
                    </Box>
                    
                    </CardBody>
                    <CardFooter pad="medium" background="light-2">
                        <Anchor href={"https://www.youtube.com/channel/" + channel_id} label="Visit Channel" />
                        <Box direction="row" responsive={false}>
                            {layer}
                            
                            <Box full={true} colorIndex="light-2" justify="center" align="center" margin='medium'>
                                <Anchor href="#" onClick={() => this._onClick(channel_id)} >Analyse Content</Anchor>
                            </Box>
                            
                        </Box>
                    </CardFooter>
              </Card>
            ))}
        </div>
        }
          
    
    </Fragment>
  );
            }
}

export default ChartTwo;